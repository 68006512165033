<template>
    <div>
        <v-title title="Application - Add"></v-title>

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Add application</h1>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <form @submit.prevent="submitForm" v-if="hasPermission('MerchantCentral/createApplication')">
                <div class="row">
                    <div class="col-8">
                        <mercur-card class="mt-n4 mb-4">
                            <h2 class="font-weight-normal">Application details</h2>
                            <mercur-input id="applicationName" v-model="form.application.applicationName" :class="getValidationClass($v, ['application', 'applicationName'])">
                                Application Name
                                <template #note>
                                    <span class="form-error" v-if="!$v.form.application.applicationName.required">Application name is required</span>
                                </template>
                            </mercur-input>
                            <div class="d-flex">
                                <mercur-input class="flex-grow-1" id="applicationSecret" v-model="form.application.applicationSecret" :class="getValidationClass($v, ['application', 'applicationSecret'])">
                                    Application Secret
                                    <template #note>
                                        <span class="form-error" v-if="$v.form.application.applicationSecret.$invalid">Application secret needs to be at least 10 characters</span>
                                    </template>
                                </mercur-input>
                                <mercur-button @click.native="form.application.applicationSecret = generatePassword($v.form.application.applicationSecret.$params.minLength.min)" class="mb-3 btn btn-primary btn-icon btn-icon-square"><i class="fas fa-redo"></i></mercur-button>
                            </div>
                        </mercur-card>

                        <mercur-card class="mb-4">
                            <h2 class="font-weight-normal">Anonymous user details</h2>
                            <mercur-input id="email" v-model="form.account.email" type="email" :class="getValidationClass($v, ['account', 'email'])">
                                Email
                                <template #note>
                                    <span class="form-error" v-if="$v.form.account.email.$invalid">Email is not valid</span>
                                </template>
                            </mercur-input>
                            <div class="d-flex">
                                <mercur-input class="flex-grow-1" id="password" v-model="form.account.password" :class="getValidationClass($v, ['account', 'password'])">
                                    Password
                                    <template #note>
                                        <span class="form-error" v-if="$v.form.account.password.$invalid">Password is required</span>
                                    </template>
                                </mercur-input>
                                <mercur-button @click.native="form.account.password = generatePassword()" class="mb-3 btn btn-primary btn-icon btn-icon-square"><i class="fas fa-redo"></i></mercur-button>
                            </div>
                        </mercur-card>

                        <div class="text-right">
                            <mercur-button class="btn" to="/applications">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Add Application</mercur-button>
                        </div>

                    </div>
                </div>
            </form>
            <span v-else>
                Not allowed to see this view
            </span>
        </div>
    </div>
</template>
<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required, email, minLength } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import { generatePassword } from '@/utils/helpers'

export default {
    name: 'AddApplication',
    mixins: [ResourcesView, FormMixin],
    data () {
        return {
            url: null,
            action: CONFIG.API.ROUTES.APPLICATIONS.ADD,
            form: {
                application: {
                    applicationName: null,
                    applicationSecret: null,
                },
                account: {
                    password: null,
                    email: null,
                },
            },
            isNotApplicationBound: true,
        }
    },
    validations: {
        form: {
            application: {
                applicationName: {
                    required,
                },
                applicationSecret: {
                    required,
                    minLength: minLength(10),
                },
            },
            account: {
                password: {
                    required,
                },
                email: {
                    required,
                    email,
                },
            },
        },
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    methods: {
        generatePassword,
        submitForm () {
            const payload = this.form
            payload.account.username = payload.account.email

            this.submit(this.action, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Application was added',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'ApplicationsWrapper',
                    })
                }, 1000)
            })
        },
    },
    created () {
        this.url = CONFIG.API.ROUTES.APPLICATIONS.OVERVIEW
    },
}
</script>
